.Link:hover {
    text-decoration: none;
}

.projCard, .randomCard {
    text-align: center;
    align-items: center;
    margin-top: 3.125em;
    margin-bottom: 8.6em;
    margin-right: 4.125em;
    margin-left: 0em;
    overflow:hidden;
}

.projCard:hover {
    transform: scale(1.25);

}

.cardTitle {
    text-decoration: underline;
}
.cardText {
    color: black;
}

.projImg {
    width: 90%;
    height: auto;
}